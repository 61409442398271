import { GenerateOrTranslateProductPageQuestionsContent } from '@dialog/notification-contracts';
import { ProductQuestion } from '@dialog/search-contracts';

export interface ProductQuestions {
  previewQuestions?: ProductQuestion[];
  productName: string;
  productId: string;
  question1: string;
  question2: string;
  question3: string;
}

export interface ProductQuestionsBanner
  extends GenerateOrTranslateProductPageQuestionsContent {
  title: string;
  index: number;
}

export const PREV_ACTION = 'prev' as const;
export const NEXT_ACTION = 'next' as const;
export type PaginationAction = typeof PREV_ACTION | typeof NEXT_ACTION;
export interface ActionPaginationDetails {
  action: PaginationAction;
  keys: { pk: string; sk: string };
}
export interface PaginationInfos {
  next?: {
    PK: string;
    SK: string;
  };
  prev?: {
    PK: string;
    SK: string;
  };
}
