import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import { ShopLocale } from '@dialog/organization-contracts';

import { useGetProductPageQuestions } from 'hooks/products';

import { useOrganization } from '../../../components/OrganizationProvider/OrganizationProvider';
import {
  ActionPaginationDetails,
  NEXT_ACTION,
  PaginationAction,
  PaginationInfos,
  PREV_ACTION,
  ProductQuestionsBanner,
  ProductQuestions as ProductQuestionsI,
} from '../types';

interface ProductQuestionsContext {
  selectedQuestionsIds: string[];
  setSelectedQuestionsIds: (productQuestionsIds: string[]) => void;
  currentLocale?: ShopLocale;
  setCurrentLocale: (locale: ShopLocale) => void;
  banners: ProductQuestionsBanner[];
  setBanners: (banners: ProductQuestionsBanner[]) => void;
  isLoadingProductPageQuestions: boolean;
  productPageQuestions: ProductQuestionsI[];
  setProductQuestions: (productQuestions: ProductQuestionsI[]) => void;
  handleChangePagination: (action: PaginationAction) => void;
  currentPagination?: PaginationInfos;
}

export const ProductQuestionsContext = createContext<ProductQuestionsContext>({
  selectedQuestionsIds: [],
  setSelectedQuestionsIds: () =>
    console.error('Product questions context is not well initialized'),
  currentLocale: undefined,
  setCurrentLocale: () =>
    console.error('Product questions context is not well initialized'),
  banners: [],
  isLoadingProductPageQuestions: false,
  productPageQuestions: [],
  setProductQuestions: () => {
    console.error('Product questions context is not well initialized');
  },
  setBanners: () =>
    console.error('Product questions context is not well initialized'),
  handleChangePagination: () => console.error('Not initialized'),
  currentPagination: undefined,
});
export const useProductQuestions = (): ProductQuestionsContext =>
  useContext(ProductQuestionsContext);

export const ProductQuestionsProvider = ({
  children,
}: {
  children: JSX.Element[] | JSX.Element;
}): JSX.Element => {
  const { organization } = useOrganization();
  const [currentLocale, setCurrentLocale] = useState(
    organization.shopLocales?.find(locale => locale.primary),
  );
  const [productPageQuestions, setProductPageQuestions] = useState<
    ProductQuestionsI[]
  >([]);
  const [currentPagination, setCurrentPagination] = useState<PaginationInfos>();
  const [pageInfo, setPageInfo] = useState<ActionPaginationDetails>();

  const handleChangePagination = useCallback(
    (action: PaginationAction) => {
      const actionInfos = currentPagination?.[action];
      if (actionInfos === undefined) return;
      setPageInfo({
        action,
        keys: {
          pk: actionInfos.PK,
          sk: actionInfos.SK,
        },
      });
    },
    [currentPagination],
  );

  const { isLoading } = useGetProductPageQuestions({
    prev: pageInfo?.action === PREV_ACTION ? pageInfo.keys : undefined,
    next: pageInfo?.action === NEXT_ACTION ? pageInfo.keys : undefined,
    organizationSlug: organization.slug,
    locale: currentLocale?.locale ?? 'en',
    onSuccess: data => {
      setCurrentPagination({
        next: data.next,
        prev: data.prev,
      });
      const formattedProductPageQuestions = data.items.map(
        productPageQuestion => ({
          productName: productPageQuestion.productTitle,
          productId: productPageQuestion.productId,
          previewQuestions: productPageQuestion.previewQuestions,
          question1:
            productPageQuestion.previewQuestions?.[0]?.question ??
            productPageQuestion.questions[0]?.question ??
            '',
          question2:
            productPageQuestion.previewQuestions?.[1]?.question ??
            productPageQuestion.questions[1]?.question ??
            '',
          question3:
            productPageQuestion.previewQuestions?.[2]?.question ??
            productPageQuestion.questions[2]?.question ??
            '',
        }),
      );
      setProductPageQuestions(formattedProductPageQuestions);
    },
  });

  const [selectedQuestionsIds, setSelectedQuestionsIds] = useState<string[]>(
    [],
  );
  const [banners, setBanners] = useState<ProductQuestionsBanner[]>([]);

  const contextValue = useMemo(() => {
    return {
      selectedQuestionsIds,
      setSelectedQuestionsIds,
      currentLocale,
      setCurrentLocale,
      banners,
      setBanners,
      productPageQuestions,
      setProductQuestions: setProductPageQuestions,
      isLoadingProductPageQuestions: isLoading,
      handleChangePagination,
      currentPagination,
    };
  }, [
    selectedQuestionsIds,
    productPageQuestions,
    setSelectedQuestionsIds,
    isLoading,
    currentLocale,
    setCurrentLocale,
    banners,
    setBanners,
    handleChangePagination,
    currentPagination,
  ]);

  return (
    <ProductQuestionsContext.Provider value={contextValue}>
      {children}
    </ProductQuestionsContext.Provider>
  );
};
